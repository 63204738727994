var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('v-card',{staticClass:"dashboard-card pa-0 elevation-0"},[_c('v-card-text',[_c('div',{staticClass:"d-flex top-bar pt-2"},[_c('div',{staticClass:"ml-2"},[_c('v-text-field',{staticClass:"searchbar",attrs:{"placeholder":"search...","outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('div',{staticClass:"d-flex mt-5"},[_c('div',[_c('b',[_vm._v(" Customers")])])]),_c('div',{staticClass:"d-flex mt-5 pa-5",staticStyle:{"background-color":"#eff0fa","border-radius":"5px"}},[_c('div',[_c('div',{staticClass:"d-flex align-start justify-start",staticStyle:{"color":"#2b3896"}},[_c('b',[_vm._v("Total Customers")])]),_c('div',{staticClass:"d-flex align-start justify-start total-amount"},[_vm._v(" "+_vm._s(_vm.getCustomers.length)+" ")])]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-end justify-end"},[_c('v-btn',{attrs:{"small":"","dense":"","outlined":"","color":"#2b3896"},on:{"click":function($event){return _vm.addNewCustomer()}}},[_vm._v("Add New "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","dense":""}},[_vm._v("mdi-plus")])],1)],1)],1),_c('div',{staticClass:"d-flex mt-3"},[_c('div',{staticClass:"mt-4"},[_c('b',[_vm._v("History")])]),_c('v-spacer'),_c('div',{staticClass:"mr-3"},[_c('date-picker')],1)],1),_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.getCustomers,"items-per-page":5,"hide-default-header":"","height":"calc(100vh - 400px)","search":_vm.search,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return _vm._l((headers),function(header,i){return _c('th',{key:'A' + i,staticClass:"table-head"},[_c('div',{staticClass:"d-flex ml-3"},[_vm._v(" "+_vm._s(header.text)+" ")])])})}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.email == null ? 'text--disabled' : ''},[_vm._v(" "+_vm._s(item.email != null ? item.email : "No email")+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.address == null ? 'text--disabled' : ''},[_vm._v(" "+_vm._s(item.address != null ? item.address : "No address")+" ")])]}},{key:"item.total_sale",fn:function(ref){
var item = ref.item;
return [_vm._v(" ₦ "+_vm._s(Number(item.total_sale).toLocaleString())+" ")]}},{key:"item.actions1",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.actions2",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.setModal(item)}}},[_vm._v(" mdi-delete-empty ")])]}}],null,true)})],1)])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"390"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h7"},[_vm._v(" Are you sure to delete this customer? ")]),_c('v-card-text',[_vm._v("By deleting, All of its sales will be lost.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":" black","small":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red darken-1","small":"","text":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v(" Delete ")])],1)],1)],1),_c('v-snackbar',{staticClass:"mt-5",attrs:{"timeout":2000,"value":true,"absolute":"","color":_vm.snackbarColor,"shaped":"","right":true,"top":true,"text":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{staticClass:"pr-3",attrs:{"color":_vm.snackbarColor}},[_vm._v(_vm._s(_vm.getIcon)+" ")]),_vm._v(" "+_vm._s(_vm.snacbarMessage)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }